<template>
  <b-sidebar id="add-new-stock-sidebar" :visible="isEditStockSidebarActive" bg-variant="white"
    sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm"
    @change="(val) => $emit('update:is-edit-stock-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">Edit Item Stock</h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
          <!-- Full Name -->
          <validation-provider #default="validationContext" name="name" rules="required">
            <b-form-group :label="$t('Form.Name')" label-for="name">
              <v-select id="item" v-model="stateData.item_id" :state="getValidationState(validationContext)" disabled
                :options="itemOptions" :reduce="(value) => value.id" label="name" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div v-if="stateData.item_id" class="d-flex justify-content-between w-100">
            <validation-provider #default="validationContext" name="Max" :rules="stateData.item_id ? 'required' : ''">
              <b-form-group label="Max" label-for="max">
                <b-form-input id="max" v-model="stateData.max_stock" :state="getValidationState(validationContext)"
                  type="number" label="name" />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider #default="validationContext" name="Min" :rules="stateData.item_id ? 'required' : ''"
              class="mx-2">
              <b-form-group label="Min" label-for="min">
                <b-form-input id="min" v-model="stateData.min_stock" :state="getValidationState(validationContext)"
                  type="number" label="name" />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider #default="validationContext" name="Default"
              :rules="stateData.item_id ? 'required' : ''">
              <b-form-group label="Default" label-for="default">
                <b-form-input id="default" v-model="stateData.default_stock"
                  :state="getValidationState(validationContext)" type="number" label="name" />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </div>

          <validation-provider #default="validationContext" name="Global Quantity" rules="required">
            <b-form-group label="Global Quantity" label-for="status">
              <b-form-input autofocus v-model.trim="stateData.global_quantity" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- global_quantity_usage -->
          <validation-provider #default="validationContext" name="Global Quantity Usage" rules="required">
            <b-form-group label="Global Quantity Usage" label-for="qunatity-usage">
              <b-form-input id="qunatity-usage" autofocus v-model.trim="stateData.global_quantity_usage" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- status -->
          <validation-provider #default="validationContext" name="Status" rules="required">
            <b-form-group :label="$t('Form.Status')" label-for="status">
              <v-select id="status" v-model="stateData.status" :state="getValidationState(validationContext)"
                :options="optionsStatus" :reduce="(val) => val.value" :clearable="false" label="text" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit"
              :disabled="loading">
              <span v-if="!loading">Edit</span>
              <span v-else>
                <SpinnerLoading />
              </span>
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-danger" @click="hide">
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SpinnerLoading from "@/components/SpinnerLoading";

import vSelect from "vue-select";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import { onMounted, ref } from "@vue/composition-api";

import axiosItemStock from "@/services/admin/inventory/itemStock";
import axiosItem from "@/services/admin/inventory/item";
import axiosFacility from "@/services/admin/facilities";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    SpinnerLoading,

    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditStockSidebarActive",
    event: "update:is-edit-stock-sidebar-active",
  },
  props: {
    isEditStockSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const optionsStatus = [
      { text: "Active", value: true },
      { text: "Inactive", value: false },
    ];
    const stateData = ref(JSON.parse(JSON.stringify(props.itemEdit.value)));
    const resetuserData = () => {
      stateData.value = JSON.parse(JSON.stringify(props.itemEdit.value));
    };

    onMounted(() => {
      getItems();
    });

    const itemOptions = ref([]);
    const getItems = () => {
      axiosItem
        .itemList(10)
        .then(({ data }) => {
          itemOptions.value = data;
        })
        .catch(() => { });
    };

    const loading = ref(false);
    const onSubmit = () => {
      loading.value = true;
      const itemStockItem = JSON.parse(JSON.stringify(stateData));
      axiosItemStock
        .itemStockUpdate(itemStockItem.value.id, itemStockItem.value)
        .then(() => {
          loading.value = false;
          resetuserData();
          emit("editStockSuccess", true);
        })
        .catch(() => {
          emit("editStockSuccess", false);
          loading.value = false;
        });
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);
    return {
      stateData,
      loading,
      optionsStatus,
      itemOptions,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style></style>
