<template>
  <div>
    <StockFilter
      v-if="
        $can('filter', 'module_modules.type_of_modules.inventory.item_stock')
      "
      :loading="loading"
      @searchFilter="searchFilter"
      @filterPage="filterPage"
    />
    <b-card>
      <TitleTable titleTable="Stock" iconTable="GitBranchIcon" />
      <div class="m-1">
        <StockEdit
          v-if="isEditStockSidebarActive"
          :is-edit-stock-sidebar-active.sync="isEditStockSidebarActive"
          :itemEdit="itemEdit"
          @editStockSuccess="editStockSuccess"
        />
        <StockAdd
          v-if="isAddNewStockSidebarActive"
          :is-add-new-stock-sidebar-active.sync="isAddNewStockSidebarActive"
          @createStock="createStock"
        />
        <StockManualQuantity
          v-if="isManualQuantityStockSidebarActive"
          :is-manual-quantity-stock-sidebar-active.sync="
            isManualQuantityStockSidebarActive
          "
          :itemEdit="itemEdit"
          @createStock="StockManualQuantitySuccess"
        />
        <StockAddMultiple
          v-if="isAddNewStockMultipleSidebarActive"
          :is-add-new-stock-multiple-sidebar-active.sync="
            isAddNewStockMultipleSidebarActive
          "
          @createStockMultiple="createStockMultiple"
        />
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("Show") }}</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("Entries") }}</label>
          </b-col>
          <!-- {{ $t("Search") }} -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <GoBack class="" />
              <!-- <b-button
                v-if="
                  $can(
                    'store',
                    'module_modules.type_of_modules.inventory.item_stock'
                  )
                "
                variant="primary"
                class="mx-1"
                @click="isAddNewStockSidebarActive = true"
                :disabled="loading"
              >
                <span v-if="!loading" class="text-nowrap"
                  >{{ $t("Add") }} Item Multiple Stock</span
                >
                <span v-else>
                  <SpinnerLoading />
                </span>
              </b-button> -->
              <b-button
                class="mx-1"
                variant="info"
                @click="getPdf()"
                :disabled="loading"
              >
                <span v-if="!loading" class="text-nowrap">
                  <feather-icon icon="PrinterIcon" class="pdf-icon" />
                  Save Pdf
                </span>
                <span v-else>
                  <SpinnerLoading />
                </span>
              </b-button>
              <b-button
                v-if="
                  $can(
                    'store',
                    'module_modules.type_of_modules.inventory.item_stock'
                  )
                "
                class="mx-1"
                variant="primary"
                @click="isAddNewStockMultipleSidebarActive = true"
                :disabled="loading"
              >
                <span v-if="!loading" class="text-nowrap"
                  >{{ $t("Add") }} Items</span
                >
                <span v-else>
                  <SpinnerLoading />
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-if="items === null">
        <b-alert show fade class="mb-0 p-1" variant="primary">
          <div class="alert-body text-center">
            <span>Por favor seleccione una facility</span>
          </div>
        </b-alert>
      </div>
      <b-table
        v-else
        :items="items"
        :fields="fields"
        hover
        bordered
        responsive
        primary-key="id"
        class="position-relative"
        show-empty
        empty-text="No matching records found"
      >
        <template #cell(status)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.status ? $t("Active") : $t("Inactive")
            }}</span>
          </div>
        </template>
        <template #cell(actions)="data">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            v-b-tooltip.hover.top="'Edit'"
            v-if="
              $can(
                'update',
                'module_modules.type_of_modules.inventory.item_stock'
              )
            "
            @click="editStock(data.item)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>

          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-danger"
            class="btn-icon"
            v-b-tooltip.hover.top="'Delete'"
            v-if="
              $can(
                'destroy',
                'module_modules.type_of_modules.inventory.item_stock'
              )
            "
            @click="deleteStock(data.item)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </template>
      </b-table>
      <PaginationTable
        :dataMeta="dataMeta"
        :totalUsers="totalUsers"
        :perPage="perPage"
        :currentPage="currentPage"
        @page-changed="pageChanged"
      />
    </b-card>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BSkeletonTable,
  BBadge,
  BAvatar,
  BButton,
  BAlert,
  VBTooltip,
} from "bootstrap-vue";

import axiosItemStock from "@/services/admin/inventory/itemStock";

import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";

import ToastNotification from "@/components/ToastNotification";
import SpinnerLoading from "@/components/SpinnerLoading";
import PaginationTable from "@/components/PaginationTable";
import TitleTable from "@/components/TitleTable.vue";
import LoadingTable from "@/components/LoadingTable.vue";
import GoBack from "@/components/ButtonBack.vue";

import StockEdit from "./StockEdit.vue";
import StockAdd from "./StockAdd.vue";
import StockFilter from "./StockFilter.vue";
import StockAddMultiple from "./StockAddMultiple.vue";
import StockManualQuantity from "./StockManualQuantity.vue";

import { ref } from "vue-demi";
export default {
  components: {
    BCard,
    BAlert,
    BRow,
    BCol,
    BTable,
    BSkeletonTable,
    BBadge,
    BAvatar,
    BButton,

    StockEdit,
    StockAdd,
    StockFilter,
    StockAddMultiple,
    StockManualQuantity,

    ToastNotification,
    SpinnerLoading,
    PaginationTable,
    TitleTable,
    LoadingTable,
    GoBack,
    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      fields: this.getFields(),
      items: null,
      loading: false,
      isEditStockSidebarActive: false,
      isAddNewStockSidebarActive: false,
      isManualQuantityStockSidebarActive: false,
      isAddNewStockMultipleSidebarActive: false,
      itemEdit: null,
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
      perPage: 10,
      currentPage: {
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100],
      filtro: false,
      stockFilter: null,
    };
  },
  watch: {
    perPage(newVal) {
      if (this.filtro) {
        const facility = this.$variableGlobal.facility.id;
        if (facility) {
          this.getStock(facility);
        }
      } else {
        this.searchFilter(this.stockFilter);
      }
    },
    "$variableGlobal.facility": function () {
      this.getStock(this.$variableGlobal.facility.id);
    },
  },
  methods: {
    getPdf() {
      axiosItemStock
        .generateReport(this.$variableGlobal.facility.id)
        .then((res) => {
          const blob = new Blob([res]);
          const filename = `stock_${new Date().toLocaleString()}.pdf`;

          const urlExcel = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = urlExcel;
          a.download = filename;

          document.body.appendChild(a);
          a.click();

          window.URL.revokeObjectURL(urlExcel);
          document.body.removeChild(a);
        });
    },
    getFields() {
      if (
        this.$can(
          "update",
          "module_modules.type_of_modules.inventory.item_stock"
        )
      )
        return [
          "id",
          { key: "items.name", label: "Item", sortable: true },
          { key: "max_stock", label: "Max Stock (Dosis)", sortable: true },
          { key: "min_stock", label: "Min Stock (Dosis)", sortable: true },
          {
            key: "default_stock",
            label: "Required Stock (Dosis)",
            sortable: true,
          },
          {
            key: "global_quantity",
            label: "Current Stock (Dosis)",
            sortable: true,
          },
          {
            key: "reorder_quantity",
            label: "Reorder Quantity (Dosis)",
            sortable: true,
          },
          {
            key: "global_quantity_usage",
            label: "Global Quantity Usage",
            sortable: true,
          },
          { key: "actions", label: "Actions" },
        ];
      return [
        "id",
        { key: "items.name", label: "Item", sortable: true },
        {
          key: "default_stock",
          label: "Required Stock (Dosis)",
          sortable: true,
        },
        {
          key: "global_quantity",
          label: "Current Stock (Dosis)",
          sortable: true,
        },
        {
          key: "reorder_quantity",
          label: "Reorder Quantity (Dosis)",
          sortable: true,
        },
      ];
    },
    getStock(facility) {
      axiosItemStock
        .itemStockList(this.perPage, facility)
        .then(({ data, current_page, total, per_page, ...res }) => {
          this.items = data;
          this.totalUsers = total;
          this.dataMeta.of = total;
          this.dataMeta.from = res.from;
          this.dataMeta.to = res.to;
          this.currentPage.page = current_page;
        });
    },
    editStock(item) {
      this.isEditStockSidebarActive = true;
      this.itemEdit = ref(JSON.parse(JSON.stringify(item)));
    },
    editStockSuccess(value) {
      if (value) {
        this.$refs.toast.success("Type Stock updated successfully");
      } else {
        this.$refs.toast.error("Error updating type label");
      }
      this.isEditStockSidebarActive = false;
      this.pageChanged(this.currentPage.page);
    },
    StockManualQuantitySuccess(value) {
      if (value) {
        this.$refs.toast.success("Type Stock updated successfully");
      } else {
        this.$refs.toast.error("Error updating type label");
      }
      this.isManualQuantityStockSidebarActive = false;
      this.pageChanged(this.currentPage.page);
    },
    createStock({ status }) {
      if (status) {
        this.$refs.toast.success("Stock type added successfully");
      } else {
        this.$refs.toast.danger("Stock type not added");
      }
      this.isAddNewStockSidebarActive = false;
      this.pageChanged(this.currentPage.page);
    },
    createStockMultiple({ status }) {
      if (status) {
        this.$refs.toast.success("Stock type added successfully");
      } else {
        this.$refs.toast.danger("Stock type not added");
      }
      this.isAddNewStockMultipleSidebarActive = false;
      this.pageChanged(this.currentPage.page);
    },
    deleteStock(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axiosItemStock.itemStockDelete(item.id).then((response) => {
            this.$refs.toast.success(
              "Stock type has been deleted successfully"
            );
            this.pageChanged(this.currentPage.page);
          });
        }
      });
    },
    pageChanged(page) {
      axiosItemStock
        .itemStockPagination(
          this.perPage,
          this.$variableGlobal.facility.id,
          page
        )
        .then(({ data, total, current_page, ...res }) => {
          this.items = data;
          this.totalUsers = total;
          this.dataMeta.of = total;
          this.dataMeta.from = res.from;
          this.dataMeta.to = res.to;
          this.currentPage.page = current_page;
        });
    },
    searchFilter(value) {
      if (this.filtro && value) {
        this.loading = true;
        this.stockFilter = value;
        axiosItemStock
          .itemStockFilter(this.perPage, 1, this.$variableGlobal.facility.id)
          .then(({ data, total, current_page, ...res }) => {
            this.loading = false;
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          })
          .catch(() => {
            this.loading = false;
          });
        this.loading = false;
      } else {
        this.getStock(this.$variableGlobal.facility.id);
      }
    },
    filterPage(value) {
      this.filtro = value;
    },
    editManualStock(item) {
      this.isManualQuantityStockSidebarActive = true;
      this.itemEdit = ref(JSON.parse(JSON.stringify(item)));
    },
  },
};
</script>

<style>
.pdf-icon {
  margin-right: 0.3vw;
}
</style>
