import { userAxios, apiV2 } from "@/services";

const itemStockList = async (perPage, facility) => {
  try {
    return await userAxios
      .get(`item_stock/index/${perPage}/${facility}`)
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const generateReport = async (facility) => {
  try {
    return await apiV2
      .post(
        `inventory/stock/generate-report/${facility}`,
        {},
        {
          responseType: "blob",
        }
      )
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const itemStockCreate = async (data) => {
  try {
    return await userAxios.post("item_stock", data).then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const changeGlobalQuantity = async (data, id) => {
  try {
    return await userAxios
      .post(`item_orders/stock/global/${id}`, data)
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const itemStockUpdate = async (id, data) => {
  try {
    return await userAxios
      .put(`item_stock/${id}`, data)
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const itemStockDelete = async (id) => {
  try {
    return await userAxios.delete(`item_stock/${id}`).then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const itemStockPagination = async (perPage, facility, page) => {
  try {
    return await userAxios
      .get(`item_stock/index/${perPage}/${facility}?page=${page}`)
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const itemStockFilter = async (perPage, page, facility) => {
  try {
    return await userAxios
      .get(`item_stock/index/${perPage}/${facility}?page=${page}`)
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const itemStockAssingMultiple = async (data) => {
  try {
    return await userAxios
      .post(`item_stock/assign-multiple-items`, data)
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

// const itemStockAssingMultiple = async (data) => {
//     try {
//         return await userAxios.post(`stock/global/{stockId}`, data).then(res => res.data)
//     } catch (error) {
//         console.log(error);
//     }
// }

export default {
  itemStockList,
  itemStockCreate,
  itemStockUpdate,
  itemStockDelete,
  itemStockPagination,
  itemStockAssingMultiple,
  changeGlobalQuantity,
  generateReport,
  itemStockFilter
};
